<template>
  <CCard>
    <CCardHeader>
      {{ $t("user.profile.change_password.title") }}
      <div class="card-header-actions">
        <CButton
          variant="outline"
          color="primary"
          size="sm"
          :pressed.sync="editable"
          ><CIcon name="cil-pencil" />&nbsp;{{
            $t("common.editor.button.edit")
          }}</CButton
        >
      </div>
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="onSubmit" v-if="editable">
        <CAlert show color="danger" v-for="item in errors" :key="item">{{
          item
        }}</CAlert>

        <CInput
          v-model.trim="$v.form.old_password.$model"
          @input="$v.form.old_password.$touch"
          :placeholder="
            $t('user.profile.change_password.placeholder_old_password')
          "
          type="password"
          :is-valid="isValid($v.form.old_password)"
          :invalid-feedback="$t('validation_messages.required_field')"
        >
        </CInput>

        <CInput
          v-model.trim="$v.form.new_password.$model"
          @input="$v.form.new_password.$touch"
          :placeholder="
            $t('user.profile.change_password.placeholder_new_password')
          "
          type="password"
          :is-valid="isValid($v.form.new_password)"
          :invalid-feedback="$t('validation_messages.password_constraints')"
        >
        </CInput>

        <CInput
          v-model.trim="$v.form.new_password_confirm.$model"
          @input="$v.form.new_password_confirm.$touch"
          :placeholder="
            $t('user.profile.change_password.placeholder_new_password_confirm')
          "
          type="password"
          :is-valid="isValid($v.form.new_password_confirm)"
          :invalid-feedback="
            $t('validation_messages.passwords_must_be_identical')
          "
        >
        </CInput>

        <vue-ladda
          :loading="loading"
          data-style="zoom-in"
          button-class="btn btn-block btn-primary"
          type="submit"
        >
          {{ $t("user.profile.change_password.button_change_password") }}
        </vue-ladda>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { apiUrl } from "../../http";

export default {
  name: "ProfileDataChangePassword",

  data() {
    return {
      loading: false,
      editable: false,
      errors: [],
      form: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
      },
    };
  },

  validations: {
    form: {
      old_password: { required },
      new_password: { required, minLength: minLength(8) },
      new_password_confirm: {
        required,
        sameAsNewPassword: sameAs("new_password"),
      },
    },
  },

  watch: {
    editable: function () {
      this.setForm();
    },
  },
  methods: {
    setForm() {
      this.form.old_password = "";
      this.form.new_password = "";
      this.form.new_password_confirm = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      this.$v.$touch();
      const isInvalid = this.$v.$invalid;
      if (!isInvalid) {
        this.loading = true;
        this.errors = [];
        try {
          await this.axios.post(apiUrl(`/api/account/pwchange/`), {
            oldpassword: this.form.old_password,
            password1: this.form.new_password,
            password2: this.form.new_password_confirm,
          });
          this.loading = false;
          this.editable = false;
          this.$store.dispatch("toaster/add", {
            title: "Ok!",
            text: t("user.profileData.passwordChangeOk"),
            autohide: true,
          });
        } catch (error) {
          this.loading = false;
          let errors = [];
          Object.keys(error.response.data).forEach((key) => {
            errors = errors.concat(error.response.data[key]);
          });
          this.errors = errors;
        }
      }
    },
    isValid($field) {
      const status =
        $field.$dirty && $field.$invalid === true ? false : undefined;
      return status;
    },
  },
};
</script>
